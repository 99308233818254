@charset "UTF-8";
// {{> pagination }}
.pagination {
    justify-content: center;
    a {
        padding: 0 10px;
    }
}
.hide {
    color: #aaa;
}
.active {
    color: $rdp-blue--dark;
}
.arrow-right {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: 30px;
        top: 50%;
        width: 15px;
        height: 15px;
        border-right: 2px solid $rdp-blue--dark;
        border-bottom: 2px solid $rdp-blue--dark;
        transform: translateY(-50%) rotate(-45deg);
    }
}
.arrow-left {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        right: 30px;
        top: 50%;
        width: 15px;
        height: 15px;
        border-right: 2px solid $rdp-blue--dark;
        border-bottom: 2px solid $rdp-blue--dark;
        transform: translateY(-50%) rotate(135deg);
    }
}