@charset "UTF-8";

// Change the Bootstrap's default .container max-width (default = 1140px).
.container {
  max-width: 1500px;
}

body {
  // Remove the Bootstrap's default horizontal -15px margin from the first .rows in the grid cells, as they go beyond the viewport.
  &,
  & > .centered {
    > * > .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &,
  * {
    font-family: "Open Sans";
  }
}

.btn.btn-primary {
  background-color: #00aeef;
  padding: 10px 25px;
  letter-spacing: 1px;
  font-size: 13px;
  color: white;
  border: none;
}
