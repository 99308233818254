@charset "UTF-8";

.header__right-side-nav {
  .navbar-nav {
    // Align the items to the right.
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    // Remove the right margin from the right-most .nav-item.
    .nav-item:last-child {
      margin-right: 0;
    }
  }
}
