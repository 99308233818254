@charset "UTF-8";

.header__mobile-menu-toggler {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;

  position: relative;
  .hamburger {
    position: absolute;
    top: 15px;
    right: 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: white;
    z-index: 999;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
