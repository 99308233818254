@charset "UTF-8";

.basic-heading {
  // General:
  color: $rdp-blue--dark;
  width: 100%;
  border-bottom: 2px solid $rdp-blue--dark;
  font-size: 26px;
  margin-bottom: 40px;
  padding-bottom: 30px;

  // Mobile-only:
  @include media-breakpoint-down(lg) {
    text-align: center;
  }

  // Desktop only:
  @include media-breakpoint-up(lg) {
    padding-top: 10px;
  }
}
