@charset "UTF-8";

.images-grid {
  display: grid;
  margin-bottom: 40px;

  img {
    width: 100%;
    padding: 10px;
    @include media-breakpoint-up(md) {
      padding: 15px;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }
    object-fit: contain;
    object-position: center;
    display: block;
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);

    // Span entire width of the grid if the last child is lonely.
    img:last-child:nth-child(odd) {
      grid-column: 1 / -1;
    }
  }

  &--3 {
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(6, 1fr);
    }

    img {
      // On phones, span entire width of the grid if the last child is lonely.
      @include media-breakpoint-down(sm) {
        &:last-child:nth-child(odd) {
          grid-column: 1 / -1;
        }
      }

      @include media-breakpoint-up(md) {
        // On tablets, take 2 columns for each one image.
        grid-column: span 2;

        // When the last child is 1st item in row, make it span entire grid's width.
        &:nth-child(3n + 1):last-child {
          grid-column: 2 / 6;
        }

        // When the last child is 2nd item in row, make it span 2 columns as wall as its previous sibling.
        &:nth-child(3n + 1):nth-last-child(2) {
          &,
          & + img {
            grid-column: span 3;
          }
        }
      }
    }
  }

  &--4 {
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--5 {
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(6, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }

    img {
      // On phones, span entire width of the grid if the last child is lonely.
      @include media-breakpoint-down(sm) {
        &:last-child:nth-child(odd) {
          grid-column: 1 / -1;
        }
      }

      @include media-breakpoint-only(md) {
        // On tablets, take 2 columns for each one image.
        grid-column: span 2;

        // When the last child is 1st item in row, make it span entire grid's width.
        &:nth-child(3n + 1):last-child {
          grid-column: 2 / 6;
        }

        // When the last child is 2nd item in row, make it span 2 columns as wall as its previous sibling.
        &:nth-child(3n + 1):nth-last-child(2) {
          &,
          & + img {
            grid-column: span 3;
          }
        }
      }
    }
  }
}
