@charset "UTF-8";

ol.with-heading {
  .heading {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .content {
    margin-bottom: 15px;
  }

  li:last-child {
    margin-bottom: 40px;
  }
}
