@charset "UTF-8";

.statute {
  .heading {
    margin-bottom: 30px;
  }

  .content {
    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}
