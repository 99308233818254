@charset "UTF-8";

@import "./tabs";

[data-tabulator] {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  margin-bottom: 40px;

  > *:last-child {
    .tabulator-row:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.tabulator-header {
  background-color: $rdp-blue--dark;
  color: white;
  font-size: 15px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .tabulator-headers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .tabulator-col {
      padding-top: 25px;
      padding-bottom: 20px;

      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }

      &:first-child {
        .tabulator-col-title {
          text-align: center;
          padding-right: 10px;
        }
      }

      // Hover action.
      transition: all 100ms ease-in-out;
      &:hover {
        cursor: pointer;
        background-color: #2a59ab;
      }

      // Active sorting indicators.
      &[aria-sort="desc"],
      &[aria-sort="asc"] {
        background-color: #2a59ab;

        // Basic indicator's skeleton.
        position: relative;
        &::before {
          content: "";
          position: absolute;

          top: 10px;
          right: 50%;
          transform: translateX(50%);
          @include media-breakpoint-up(md) {
            top: 50%;
            right: 10px;
          }
          display: block;

          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }

        // Top-facing arrow:
        &[aria-sort="asc"]::before {
          border-bottom: 5px solid white;
        }

        // Down-facing arrow:
        &[aria-sort="desc"]::before {
          border-top: 5px solid white;
        }
      }
    }

    .tabulator-col-title {
      text-align: center;
      padding: 0 5px;
    }
  }
}

.tabulator-tableHolder {
  &:focus,
  &:active {
    outline: none;
  }

  .tabulator-table {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .tabulator-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      &:nth-child(odd) {
        background-color: #fafafa;
      }
      &:nth-child(even) {
        background-color: #f1f1f1;
      }

      .tabulator-cell {
        padding: 20px 10px;
        line-height: 1.4;

        display: flex;
        align-items: center;
        &:not(:first-child) {
          justify-content: center;
        }
      }
    }
  }
}

.tabulator-footer {
  background-color: #00aeef;
  color: white;
  font-size: 15px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .tabulator-calcs-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .tabulator-cell {
      padding-top: 25px;
      padding-bottom: 20px;

      @include media-breakpoint-down(sm) {
        &:nth-child(2) {
          position: relative;
          &::after {
            content: "SUMA";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      @include media-breakpoint-up(md) {
        &:nth-child(3) {
          position: relative;
          &::after {
            content: "SUMA";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
