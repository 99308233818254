@charset "UTF-8";

.form-check {
  padding-left: 0;

  // Align the validation feedback to the right
  .error-info {
    right: 0 !important;
  }

  // Remove the Bootstrap's default padding.
  padding-top: 0;

  // Add lacking bottom margin.
  margin-bottom: 1rem;

  input:focus ~ .checkmark {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  // Additional styling for the invalid checkbox.
  &.is-invalid {
    padding-top: 20px;
  }
}

.form-check__container {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;

  // Hide the browser's default checkbox.
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // Create a custom checkbox.
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid black;

    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition-property: border-color, box-shadow, -webkit-box-shadow;
    transition-duration: 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s;
  }

  // Create the checked input indicator.
  .checkmark::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 2px;
    width: 6px;
    height: 13px;
    border: solid black;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);

    // Hide on load.
    display: none;
  }

  // Show the checkmark with the input check.
  input:checked ~ .checkmark::after {
    display: block;
  }
}
