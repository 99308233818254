@charset "UTF-8";

.header__left-side-nav {
  .navbar-nav {
    // Align the items to the left.
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }

    // Remove the left margin from the light-most .nav-item.
    .nav-item:first-child {
      margin-left: 0;
    }
  }
}
