@charset "UTF-8";

.aside {
  background-color: $rdp-blue--light;
  padding: 25px 5px;
  @include media-breakpoint-up(lg) {
    padding: 25px 0 15px 0;
  }

  @include media-breakpoint-up(lg) {
    border-radius: 15px;
    margin-right: 10px;
  }

  &__schedule {
    color: $rdp-blue--dark;

    h2 {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 20px;
    }

    // Style the horiznotal lines (spacers).
    hr {
      border-top: 2px solid $rdp-blue--dark;
      margin: 0;
    }

    dl {
      margin: 0;
    }

    &__single-date {
      // Lay-out the content of the heading.
      .wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        dt,
        dd {
          display: block;
          line-height: 1;
        }
      }

      // Add space between the items.
      dt {
        margin-bottom: 5px;
      }
      dd {
        margin: 0;
      }

      padding-top: 30px;
      &:last-child {
        padding-bottom: 30px;
      }

      // Style the text
      .wrapper {
        .date {
          font-size: 40px;
          font-weight: 700;
        }

        .description {
          font-size: 18px;
        }
      }
    }
  }

  &__fb-like-box {
    margin: 30px;
  }

  &__logotypes {
    // Style the white boxes (callouts).
    .callout {
      background-color: white;
      border-radius: 10px;
      padding: 10px;

      // Add space between the .callouts.
      &:last-child {
        margin-top: 30px;
      }
    }

    // Style the headings.
    .heading {
      font-size: 12px;

      &--organisers {
        margin: 10px 0;
      }

      &--media-patrons {
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }

    // Style the images.
    img {
      object-fit: contain;
      object-position: center;
      padding: 10px;
    }

    &__media-patrons {
      img {
        height: 70px;
        width: 100%;
      }
    }
  }
}
