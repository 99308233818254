@charset "UTF-8";

@import "../../components/header/navbars";
@import "./left-side-nav";
@import "./right-side-nav";
@import "./main-heading";
@import "./rdp-logotype";
@import "./tczew-logotype";
@import "./mobile-menu-toggler";
@import "./mobile-menu";

.header {
  // Span the entire viewport height on the mobile devices.
  @include media-breakpoint-down(lg) {
    height: 100vh;

    > .container {
      height: 100%;

      > .header__grid {
        height: 100%;
      }
    }
  }

  // Hide the desktop-only elements on mobile.
  @include media-breakpoint-down(lg) {
    &__left-side-nav,
    &__right-side-nav,
    &__tczew-logotype {
      display: none;
    }
  }
  //Show the desktop view
  @include media-breakpoint-up(lg) {
    &__left-side-nav,
    &__right-side-nav,
    &__tczew-logotype {
      display: block;
    }
  }

  // Hide the mobile-only elements on desktop.
  @include media-breakpoint-up(lg) {
    &__mobile-menu-toggler,
    &__mobile-menu {
      display: none;
    }
  }

  // Define the CSS Grid.
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 70px 150px 150px;
    }
  }

  // Styling for the 'compact' version of the <header>.
  &.mobile-without-heading {
    @include media-breakpoint-down(md) {
      height: 40vh;
      background-position: center left;

      .header__grid {
        grid-template-rows: auto;
      }

      .header__main-heading {
        display: none;
      }
    }
  }

  // Add background image.
  background-image: url("../assets/images/sections/header/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-up(lg) {
    background-position: center -200px;
  }

  // Lay-out the content within the grid.
  &__rdp-logotype {
    grid-column: 1;
    grid-row: 1;
    @include media-breakpoint-up(lg) {
      grid-column: 6 / 8;
      grid-row: 1 / 3;
    }
  }

  &__tczew-logotype {
    grid-column: 1 / 6;
    grid-row: 2;
  }

  &__mobile-menu-toggler {
    grid-column: 2;
    grid-row: 1;
  }

  &__main-heading {
    grid-column: 1 / -1;
    grid-row: 2;
    @include media-breakpoint-up(lg) {
      grid-row: 3;
    }
  }

  &__left-side-nav,
  &__right-side-nav {
    grid-row: 1;
    align-self: end;
    padding-bottom: 20px;
  }

  &__left-side-nav {
    grid-column: 1 / 6;
  }

  &__right-side-nav {
    grid-column: 8 / -1;
  }
}
