@charset "UTF-8";

////
/// This files makes sure that the page will render at least similarily on all different browsers.
///
/// @author Stanisław Gregor <stanislaw.gregor@compania.com.pl>
////

* {
  box-sizing: border-box;
  font-family: sans-serif;
}

*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
