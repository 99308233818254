@charset "UTF-8";

.nav-tabs.table-tabs {
  // Remove the Bootstrap's default border styles.
  border: none;

  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .nav-item {
    flex: 1;
    margin: 0;

    &:first-child {
      .nav-link {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child {
      .nav-link {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .nav-link {
    // Remove the Bootstrap's default border styles.
    border: none;

    text-align: center;
    height: 100%;
    width: 100%;
    padding: 20px 0;
    font-size: 15px;

    background-color: #f1f1f1;
    color: black;
    &.active {
      background-color: #00aeef;
      color: white;
    }
  }
}
