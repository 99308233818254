@charset "UTF-8";

@import "../components/main/form";
@import "../components/main/basic-heading";
@import "../components/main/images-grid";
@import "../components/main/basic-ul";
@import "../components/main/ol-with-heading";
@import "../components/main/statute";
@import "../components/main/grids/grids";
@import "../components/main/tables/tables";

.main {
  @include media-breakpoint-down(lg) {
    padding: 30px 0;
  }

  p {
    line-height: 1.4;
    margin-bottom: 40px;
  }
}
