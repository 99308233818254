@charset "UTF-8";

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf");
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-BoldItalic.ttf");
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-family: "Open Sans";
  font-weight: 800;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf");
  font-family: "Open Sans";
  font-weight: 800;
  font-style: italic;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-Italic.ttf");
  font-family: "Open Sans";
  font-weight: 400;
  font-style: italic;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-Light.ttf");
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-LighItalic.ttf");
  font-family: "Open Sans";
  font-weight: 300;
  font-style: italic;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf");
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf");
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
}

@font-face {
  src: url("../assets/fonts/Rajdhani/Rajdhani-Bold.ttf");
  font-family: "Rajdhani";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/Rajdhani/Rajdhani-Light.ttf");
  font-family: "Rajdhani";
  font-weight: 300;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/Rajdhani/Rajdhani-Medium.ttf");
  font-family: "Rajdhani";
  font-weight: 500;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/Rajdhani/Rajdhani-Regular.ttf");
  font-family: "Rajdhani";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url("../assets/fonts/Rajdhani/Rajdhani-SemiBold.ttf");
  font-family: "Rajdhani";
  font-weight: 600;
  font-style: normal;
}
