@charset "UTF-8";

.main__grid__single-tile {
  $tile-padding-sm: 30px;
  $tile-padding-md: 35px;
  $tile-padding-lg: 40px;

  // Lay-out the contents.
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: $tile-padding-sm;
  @include media-breakpoint-up(md) {
    padding: $tile-padding-md;
  }
  @include media-breakpoint-up(lg) {
    padding: $tile-padding-lg;
  }

  .element--top {
    margin-bottom: $tile-padding-sm;
    @include media-breakpoint-up(md) {
      margin-bottom: $tile-padding-md;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: $tile-padding-lg;
    }
  }

  .element--bottom {
    margin: 0;
  }

  // Border radius.
  border-radius: 10px;
}
