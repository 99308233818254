@charset "UTF-8";

@import "../../components/main/checkboxes";

.form-group,
.form-check {
  // Style the label.
  label {
    font-size: 12px;
  }

  // Style the inputs text.
  input,
  select,
  textarea {
    font-size: 15px;
    background-color: #fafafa;
  }

  // Add the red asterisk sign on the end of required fields' labels.
  &.required {
    label::after {
      content: "*";
      color: #dc3545;
      margin-left: 4px;
    }
  }

  // Styles for the validation error information.
  position: relative;
  .error-info {
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 12px;
    line-height: 1;
    color: #dc3545;

    // Hide on default.
    display: none;
  }

  // When the input was invalid:
  &.is-invalid {
    // Colour the input's value in red.
    input,
    select,
    textarea {
      color: #dc3545;
    }

    // Show the validation feedback.
    .error-info {
      display: block;
    }

    // Add red outline to the input.
    .form-control,
    .checkmark {
      border-color: #dc3545;
    }
    .form-control {
      &:focus,
      &:active {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }
}

// Style the paragraph with the additional information.
.additional-info {
  font-size: 12px;
  margin: 30px 0;
}

// Fix the oversized left padding.
select.form-control {
  padding-left: 7px;
}

// Style the submit button.
button[type="submit"] {
  background-color: $rdp-blue--dark;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover,
  &:focus {
    color: white;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

// Style the Google Maps map.
#map {
  height: 500px;
  max-height: 70vh;
  width: 100%;
  margin-bottom: 20px;
}
