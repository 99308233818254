@charset "UTF-8";

.aside__logged-in {
  // aside__img sandwich margin
  .images-grid {
    margin: 40px 0;
  }
  //aside_information background list
  .list {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
}
//aside__score
.score {
  font-size: 130px;
  padding: 40px 0;
}
//aside__text and aside__information (<h2>)
.description {
  padding: 10px 0;
}
//aside__information
.description-main {
  line-height: 1.4;
}
//aside__information list
.aside-ul {
  list-style-type: disc;
  margin-left: 20px;
}
//aside__information text padding and color
.information-text {
  padding-bottom: 20px;
  color: #000;
  line-height: 1.4;
}
