@charset "UTF-8";
//<!--{{> account__menu-logged-in }}-->
.account__menu {
  //style for after element account menu mobile
  .collapse-wrapper .collapse-toggler::after {
    border-right: 2px solid $rdp-blue--dark;
    border-bottom: 2px solid $rdp-blue--dark;
  }
  //style for button account menu mobile
  .my-account-mobile {
    color: $rdp-blue--dark;
    background: #fff;
    border-radius: 10px;
  }
}
