@charset "UTF-8";

.header__main-heading {
  align-self: center;

  h1 {
    font-size: 50px;

    &,
    small {
      font-family: "Rajdhani";
      color: white;
      font-weight: bold;
    }

    small {
      display: block;
      margin-bottom: 10px;
      @include media-breakpoint-down(md) {
        font-size: 30px;
        margin-bottom: 50px;
      }
    }
  }
}
