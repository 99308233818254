@charset "UTF-8";

.header__rdp-logotype {
  img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: contain;
    object-position: center top;
    margin-top: -60px;
    @include media-breakpoint-up(lg) {
      margin-top: -40px;
    }
  }
}
