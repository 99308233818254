@charset "UTF-8";

.footer {
  &__upper {
    background-color: #f1f1f1;

    .logotypes {
      // Align the images in the columns.
      .col-12 {
        // Mobile:
        // Each to the middle.
        text-align: center;

        // Desktop:
        @include media-breakpoint-up(lg) {
          // To the LEFT in the 1st column.
          &:nth-child(1) {
            text-align: left;
          }

          // To the MIDDLE in the 2nd column.
          &:nth-child(2) {
            text-align: center;
          }

          // To the RIGHT in the 3rd column.
          &:nth-child(3) {
            text-align: right;
          }
        }
      }

      // Add space between the images on mobile.
      @include media-breakpoint-down(lg) {
        .col-12 {
          &:nth-child(1),
          &:nth-child(2) {
            padding-bottom: 50px;
          }
        }
      }
    }
  }

  &__lower {
    background-color: $rdp-blue--dark;

    p {
      color: white;
    }
  }

  &__cookies-policy {
    // Style the buttons.
    .accept-button,
    .info-button {
      background-color: $rdp-blue--dark;
      color: white;
      font-weight: bold;
      padding: 10px;
      font-size: 12px;
      width: 150px;
      @include media-breakpoint-up(md) {
        font-size: 15px;
        width: 200px;
      }

      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover,
      &:focus {
        color: white;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }

      // Add space between the buttons.
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
}
