body {
  display: grid;
  width: 100%;
  height: 100%;

  // Mobile:
  // 1 column
  grid-template-columns: [main-start] 1fr [main-end];
  // 4 rows
  grid-template-rows: [header-start] auto [header-end content-start] auto [content-end aside-start] auto [aside-end footer-start] auto [footer-end];

  // Desktop:
  @include media-breakpoint-up(lg) {
    // 2 columns (2:1 ratio)
    grid-template-columns:
      [full-width-start] auto [center-start] minmax(min-content, 1500px)
      [center-end] auto [full-width-end];
    // 3 rows
    grid-template-rows: [header-start] auto [header-end center-start] auto [center-end footer-start] auto [footer-end];

    // Specify how the elements should fit the grid's cells.
    header {
      grid-column: full-width;
      grid-row: header;
    }

    .centered {
      grid-area: center;
      padding: 30px 0;

      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: [content-start] 2fr [content-end aside-start] 1fr [aside-end];
      grid-template-rows: auto;
      align-items: start;

      main {
        grid-column: content;
      }

      aside {
        grid-column: aside;
      }
    }

    footer {
      grid-column: full-width;
      grid-row: footer;
    }
  }
}
