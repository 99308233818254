@charset "UTF-8";

.navbar-nav {
  // Lay-out the <li>s.
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 99;
}

.nav-item {
  // Add space between the items.
  margin: 15px 0;
  @include media-breakpoint-up(lg) {
    margin: 0 15px;
  }

  // Style the text.
  a {
    color: white;
  }
}

.nav-link {
  font-size: 24px;
  @include media-breakpoint-up(lg) {
    font-size: 15px;
  }
}

// Style the dropdown.
.dropdown-menu {
  border-radius: 10px;
  background-color: rgba($color: $rdp-blue--light, $alpha: 0.9);
  border: 0;
  padding: 0;

  .dropdown-item {
    padding: 10px 15px;

    // Align the border-radius with the parent's.
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  a {
    color: $rdp-blue--dark;
    font-size: 12px;
  }
}

// Style the collapse.
.collapse-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  a {
    color: white;
  }

  .collapse-toggler {
    padding-right: 35px;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 13px;
      top: 50%;
      width: 10px;
      height: 10px;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: translateY(-50%) rotate(45deg);
      transition: all 200ms ease-in-out;
    }
  }

  .collapse-inner {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    a {
      display: block;
      text-align: center;
      font-size: 15px;
      margin-bottom: 15px;
      &:first-child {
        padding-top: 10px;
      }
    }

    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }

  &.expanded {
    .collapse-toggler::after {
      transform: rotate(225deg);
    }

    .collapse-inner {
      max-height: 1000px;
    }
  }
}

.nav-item,
.nav-link {
  &.bordered,
  &.with-white-background {
    border-radius: 10px;

    a {
      padding: 10px 15px;
    }

    // Make space between the .dropdown-menu.
    .dropdown-menu {
      top: 10px !important;
    }
  }

  &.bordered {
    border: 1px solid white;
  }

  &.with-white-background {
    background-color: white;

    &.collapse-toggler {
      padding-left: 14px;
    }

    // Change the text colour.
    &,
    a {
      color: $rdp-blue--dark;
    }
  }
}
