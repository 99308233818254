@charset "UTF-8";

.header__tczew-logotype {
  align-self: stretch;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    padding: 5px 0;
  }
}
