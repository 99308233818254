@charset "UTF-8";

.basic-ul {
  list-style-type: disc;
  margin-left: 20px;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  // In case of the nested <ul>s:
  .basic-ul {
    li:first-child {
      margin-top: 10px;
    }

    li:last-child {
      margin-bottom: 10px;
    }
  }
}
