@charset "UTF-8";

.header__mobile-menu {
  opacity: 0;
  z-index: -1;
  transition: all 300ms ease-in-out;
  &.visible {
    opacity: 1;
    z-index: 2;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    background-image: url("../assets/images/sections/header/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(5px);
  }

  .nav-link.bordered {
    padding: 10px 40px 10px 20px;

    &::after {
      right: 15px;
    }
  }
}

body.locked-scroll {
  overflow: hidden;
}
