@charset 'UTF-8';

.main__grid-shop {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-row-gap: 40px;

  @include media-breakpoint-up(md) {
    grid-column-gap: 40px;
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    .main__grid__single-tile:last-child:nth-child(odd) {
      grid-column: 1 / -1;
      width: calc(50% - 20px);
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  margin-bottom: 40px;

  .main__grid__single-tile {
    border: 1px solid rgba($color: black, $alpha: 0.1);
    padding: 0;
  }

  .item__photo {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0;
  }

  .element--bottom {
    padding: 20px 20px 20px;
  }

  .item__title,
  .item__description {
    text-align: center;
  }

  .item__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1;
  }

  .item__description {
    margin: 0;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .item__buy-button {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      padding: 11px 30px;
      color: white;
      font-size: 15px;
      font-weight: bold;
      background-color: $rdp-green--dark;
      border-radius: 5px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        text-decoration: none;
        cursor: pointer;
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25),
          0 5px 5px rgba(0, 0, 0, 0.22);
      }
    }
  }
}
