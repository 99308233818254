@charset 'UTF-8';

.main__grid-badges {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-row-gap: 40px;

  @include media-breakpoint-up(md) {
    grid-column-gap: 40px;
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    .main__grid__single-tile:last-child:nth-child(odd) {
      grid-column: 1 / -1;
      width: calc(50% - 20px);
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  margin-bottom: 40px;

  .main__grid__single-tile {
    border: 1px solid rgba($color: black, $alpha: 0.1);
    padding: 40px 20px 20px 20px;
  }

  .badge__photo {
    height: 120px;
    width: 100%;
    object-fit: contain;
    object-position: center;
    margin-bottom: 40px;
  }

  .badge__title,
  .badge__description {
    text-align: center;
  }

  .badge__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .badge__description {
    margin: 0;
  }
}
