@charset "UTF-8";

.main__grid-statistics {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-row-gap: 40px;

  @include media-breakpoint-up(md) {
    grid-column-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  margin-bottom: 40px;

  .main__grid-statistics__single-tile {
    // Add green border.
    border: 1px solid $rdp-green--dark;

    // Style the contents.
    .number,
    .text {
      text-align: center;
      line-height: 1;
    }

    .number {
      font-size: 60px;
      font-weight: 700;
      color: $rdp-green--dark;
    }
  }
}
